.empty__post__container{
    margin-bottom: 30px;
}
.empty__gongji__box{
    border-radius: 5px;
    margin-left: 30px 50px;
    padding: 20px;
    background-color:#e5c36d;
    /* height: 100px; */
}
.empty__header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty__h4{
    font-family: "nanum";
    font-size: 2rem;
    margin: 20px auto;
    color:royalblue;
    font-family: 'Nanum Pen Script', cursive, sans-serif;

}