.reply__form__container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reply__form{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
}
.form__self{
    display: flex;
    width: 100%;
    margin-bottom:5px ;
   
    
}
.reply__input{
    outline: none;
    border:none;
    opacity: 0.8;
    font-size:0.9rem;
    padding: 10px 10px;
    margin-right: 5px;
    border-radius: 10px;
    width:100%;
    background-color: white;
}
form button {
    /* width: 40px; */
    height: 40px;
    align-self: center;
}