.modal__newPosting{
    background-color: #fefefe;
    margin: 0 auto; 
    border: 1px solid #888;
    border-radius: 5px;
    width: 100%;
    max-width: 600px;
    position: relative;
    overflow: hidden;
}
.close__newPosting{
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    right: 25px;
    top: 0;
    color: #000;
    cursor: pointer;

}
.close__newPosting:hover{
    color: crimson;
}
.form__course{
    border: none;
    outline: none;
}

