.reply__container{
    display: flex;
    justify-content: flex-end;
    /* align-items: flex-end; */
    width: 100%;
    /* margin: 5px; */
    /* padding: 5px; */
    border-bottom: solid 1px rgb(147, 146, 146);
    border-radius: 10px;
}
.reply__avatar__div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    margin: 3px;
}
.reply__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:6;
   
}
.reply__content__upper__x{
    width: 100%;
    position: relative;
    display:flex;
    justify-content: space-between;
    font-size: 0.8rem;
}
.comment__reply__delete_x{
    color: blue;
    cursor:pointer;
}
.reply__content__bottom{
    width: 100%;
    display: flex;
    padding: 1px;
    justify-content: space-between;
}
.reply__commenter{
    font-size: 0.7rem;
    padding-left: 10px;
    opacity:0.7;
}
.reply__bottom_right{
    display: flex;
    justify-content: space-between; 
}
.reply__date{
    display: flex;
    font-size: 0.7rem;
    opacity:0.8;
    margin-left: 10px;
}

.reply__edit{
    color: darkgreen;
    margin-right: 15px;
    cursor: pointer;
    font-size: 0.7rem;
    opacity:0.7;
    font-weight: bold;
}
.reply__reply{
    margin-right: 5px;
    cursor: pointer;
    opacity:0.9;
    color: darkblue;
    font-size: 0.7rem;
    font-weight: bold;
}