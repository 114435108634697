@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap');

.m__container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    width: 100vw;
}
.modal {
  /* display: none;  */
   position: fixed; 
   z-index: 50; 
   left: 0;
   top: 0;
   width: 100%; 
   height: 100%; 
   overflow: auto; 
   background-color: rgba(0, 0, 0, 0.4); 
   padding-top: 60px;
 } 
.modal__box {
    display: flex;
    justify-content: center;
    align-items: center;
}
 