
:root {
  /* dark shades of primary color*/
  --main--main--color:#ADDAFB;
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-gold: #c59d5f;

  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --white-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
  --max-width: 1170px;
  /* --fixed-width: 620px; */
}

*{ 
  margin: 0;  
  padding: 0;  
  box-sizing: border-box; 
 }
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
/* HOME */

main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main__header__container {
  background:url("../public/images/header_bukhan.jpg") no-repeat center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height:15vh;
  overflow: hidden;
}
.mountain_title h2{
  font-family: 'Nanum Pen Script', cursive, sans-serif;
}
.wrapper{
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* height: 100%; */
  background-color: var(--clr-grey-10);
}
.dark{
  background-color: #222;
  color: rgb(213, 213, 213);
}
.category{
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.category__container {
  display: flex;
  justify-content: center;
}

.section__container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
    /* min-height: 95vh; */
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

.error {
  background-color: tomato;
  color:white;
  border-radius: 6px;
  padding: 6px 12px;
  font-weight: bold;
  max-width: 300px;
  margin-bottom: 18px;
}
.main__title{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head__notice__btn {
  position: absolute;
  left: 15px;
  padding: 5px;
  border: solid 1px crimson;
  border-radius: 5px;
  color:crimson;
  background-color: transparent;
  cursor: pointer;
}
/* 500 이하에서는 */
@media screen and (max-width: 500px) { 
  
  .section__container {
    width:90vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  
  /* img {
    width: 360px;
  } */
}
