.san__section {
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 1200px;
    display: grid;
    gap: 1rem 1rem;  
    grid-template-columns: 1fr 1fr; 
  }
  .san__box{
    /* display: flex;
    justify-content: center; */
    min-width: 250px;
    max-width: 28rem;
  }
  .san__info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* height: 160px; */
    max-height: 170px;
    padding:1rem;
    border: solid 1px #ccc;
    box-shadow: var(--dark-shadow);
  }
  .info__upper .san__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px dotted;
  }
  span.rank{
    font-weight: normal;
    font-size: .8rem;
  }  
  span.n_park img{
    width:20px;
  }  
  .high {
    /* color: var(--clr-grey-4); */
    letter-spacing: 0.1px;
    font-weight: normal;
    margin-left: 5px;
  }
  .san__address {
    padding-top: 0.2rem;
    font-size: 0.8rem;
    margin:10px auto;
  }
  button.san__btn{
    width: 100%;
    background-color:#ADDAFB ;
    color: black;
    border:none;
    padding: 5px auto;
    cursor: pointer;
  }
  span.saein{
    display: inline;
    margin-right: 10px;
    font-size: .5rem;
  }
  @media only screen and (max-width: 800px) {
    .san__section {
      margin: 0 auto;
      display: grid;
      gap: 1 rem 0 rem;
      grid-template-columns:1fr;
    }
   
    .san__item {
      grid-template-columns: 1fr;
      gap: 0 1rem;
      max-width: 350px;
    }
    .san__address {
      font-size: 0.7rem;
    }
    
  }  