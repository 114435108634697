.topbar__container{
    margin: 5px 0;
    /* padding: 0;
    display: flex;
    justify-content:space-between; */
}
.topbar__wrapper{
    height: 20px;
    display: flex;
    justify-content:flex-end;
    color:white;
    cursor: pointer;
}
.topbar__icon{
    margin-right: 10px;
    font-size: 10px;
}
