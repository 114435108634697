/* The Modal (background) */

/* Modal Content/Box */
.modal__content{
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
    background-color: #fefefe;
    margin: 2% auto 15% auto; 
    border: 1px solid #888;
    border-radius: 20px;
    width: 80%;
    max-width: 480px;
    position: relative;
    z-index: 50;
}
.container {
    padding: 30px 30px 10px 30px;
  }
.container__bottom{
  display: flex;
  justify-content:space-between;
  padding: 20px 30px 20px 30px;
  border-radius: 0 0 20px 20px;
}
/* Full-width input fields */
input.in__email,
input.in__password {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  /* 아래 border 넣으면, mui Text Field 안쪽 박스 생김 */
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
}
   /* Set a style for all buttons */
.login__btn {
    background-color: #04aa6d;
    color: white;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
    align-content: center;
}

.login__btn:hover, .cancelbtn:hover {
    opacity: 0.7;
    color:wheat;
}
/* Extra styles for the cancel button */

  /* The Close Button (x) */
  .close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: red;
    cursor: pointer;
  }
  .forgot__p {
    cursor: pointer;
    padding-top: 5px;
  }
  .error_login{
    display: flex;
    justify-content: center;
    background-color: rgba(242, 127, 127, 0.5);
    width: 100%;
    padding: 5px;
    font-size: 0.8rem;
    color:whitesmoke;
  }
  .google__button{
    width: 100%;
    
  }