.map__search__container{
    position: relative;
    width: 100%;
    height: 100vh;
}
.map__category{
    position: absolute;
    margin: 10px;
    top: 0;
    right: 100px;
    /* float: left; */
    /* display: flex;
    justify-content: center; */
    z-index: 100;

}
.icon__box__map{
    position: absolute;
    top:-10px;
    right:60px;

    width: 45px;
    height: 45px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1.5px royalblue;
    cursor: pointer;
    margin: 20px 0 0 20px;
    z-index: 100;
   
}
.home__icon__map{
    position: absolute;
    color:royalblue;
    font-size: 30px;
    z-index: 100;
    /* padding: 15px; */
}
.cate__box {
    background-color: transparent;
  }
.box__info__container{
    position: absolute;
    top:60px;
    right:10px;
    background-color: rgba(0,0,0,0.3);
    width: 120px;
    height: 50px;
    border-radius: 8px;
    color:white;
    padding:5px;
}
.name span{
    font-size: 0.9rem;
}