/* .cate__box{

} */
.select_box{
  padding: 8px;
  /* margin: 0 5px; */
  outline:none;
}
/* .option_box{

} */