@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

.footer__container{
    position: absolute;
    bottom:0;
    width: 100vw;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #ADDAFB;
    padding-bottom: 10px;
    margin-top: 100px;
}
.dark{
    background-color: #222;
    color: rgb(213, 213, 213);
  }
.footer__lists{
    margin: 0 auto;
    max-width: 800px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}
.footer__list__left li a{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    text-align: right;
}
.footer__list__right li a{
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    text-align: left;
}
.footer__container li{
    list-style: none;
    text-align: center;
    padding: .5px 0;
    font-size: 1rem;
}
.footer__container li a{
    font-size: .9rem;
    color: #000;
    text-decoration: none;
    opacity: 0.50;
}
.footer__box{
    margin: 10px 0;
}
.current__position{
    font-weight: bold;
    color:crimson;
    cursor:pointer;
}
.copyright, .mailto {
    display: flex;
    justify-content: center;
    font-size: .8rem;
    color: #000;
    text-decoration: none;
    opacity: 1;
    padding: 0px 0;

}
.mailto:hover {
    cursor: pointer;
    color: royalblue;
}
.footer__container li a:hover{
    opacity: 1;
}

.footer__container .wave {
    position:absolute;
    top:-100px;
    left:0;
    width: 100%;
    height: 100px;
    background:url(../../../public/images/waver1.png);
    background-size: 1000px 100px;
}
.footer__container .wave#wave1 {
    z-index: 10;
    opacity: 1;
    bottom:0;
    animation: animatewave 8s linear infinite;
}
.footer__container .wave#wave2 {
    z-index: 9;
    opacity: 0.5;
    bottom:10px;
    animation: animatewave_02 8s linear infinite;
}
.footer__container .wave#wave3 {
    z-index: 10;
    opacity: 0.2;
    bottom:15px;
    animation: animatewave 7s linear infinite;
}
.footer__container .wave#wave4 {
    z-index: 9;
    opacity: 0.7;
    bottom:20px;
    animation: animatewave_02 7s linear infinite;
}
@keyframes animatewave {
    0% {
        background-position-x: 1000px;
    }
    100% {
        background-position-x: 0px;
    }
}
@keyframes animatewave_02 {
    0% {
        background-position-x:0px;
    }
    100% {
        background-position-x: 1000px;
    }
}