
.geoposition__container{
  position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.geoposition__home__div{
  position: absolute;
  top:0;
  left:0;
  margin-top: 10px;
  margin-left: 10px;
  /* display: flex;
  justify-content: space-between; */
}
.geoposition__back__div{
  position: absolute;
  top:0;
  right:0;
  margin-top: 10px;
  margin-right: 70px;
  cursor: pointer;
  z-index: 1000;
  /* display: flex;
  justify-content: space-between; */
}

.home__icon__box, .back__icon__box{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  border: solid 1.5px royalblue;
  cursor: pointer;
}
.position_text_container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}
.position_content{
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  align-items: center;
}
.position_content h3 {
  opacity: .8;
}
.position_content>ul>li {
  padding: 0 5px;
}
li span {
    opacity: 0.6;
}
.position__map__btn{
  width: 100%;
  margin-top: 10px;
  padding: 10px auto;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: skyblue;
  border: none;
  color:whitesmoke;
  border-radius: 5px;
  cursor: pointer;
  box-shadow:var(--dark-shadow);

}
.map__container{
  width: 100%;
  height:100%;
}
@media only screen and (max-width: 799px){
    .nav__san{
      font-size: 1.2rem;
      display: block;
    }
    .mountain_title{
      display:none;
    }
    .nav__links{
      padding-top: 10px;
    }
 
    
}