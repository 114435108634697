.eInput{
    position: relative;
}

div.x__close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
  }

.x__close:hover,
.x__close:focus {
    color: red;
    cursor: pointer;
}
.close__icon{
    font-size: large;
}

input[type="email"],
input[type="text"]
{
  width: 100%;
  padding: 10px 15x;
  margin: 8px 0;
  display: inline-block;
  /* 아래넣으면, mui Text Field 안쪽 박스 생김 */
  /* border: 1px solid #ccc; */
  box-sizing: border-box;
}
.error_span{
    display: flex;
    justify-content: center;
    background-color: rgba(242, 127, 127, 0.5);
    width: 100%;
    padding: 10px;
    color:whitesmoke;
}
.login__btn {
    background-color: #04aa6d;
    color: white;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
}
.cancelbtn {
    width: auto;
    border: none;
    border-radius: 5px;
    color:white;
    padding: 10px 18px;
    background-color:crimson;
}