@import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&family=Roboto:wght@100;300;400;500;700&display=swap');

.promo__modal__content{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #fefefe;
    margin: 2% auto 15% auto; 
    border: 1px solid #888;
    border-radius: 20px;
    width: 85%;
    max-width: 450px;
    position: relative;
    z-index: 50;
  }
  .promo__upper__container {
    width: 100%;
    padding: 30px 30px 10px 30px;
    }
  .promo__container__bottom{
    display: flex;
    justify-content:space-between;
    background-color: #dcdee1;
    padding: 20px 30px 20px 30px;
    border-radius: 0 0 20px 20px;
  }
.promo__main__title{
    font-family: Roboto;
    font-size: 1.5rem;
    font-weight: 700;
}
.promo__form{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.promo__form form {
    width: 100%;
    margin-top: 10px;
}
.code__verification{
    display: flex;
    width: 100%;
    justify-content: center;
}

.promocode__input{
    width: 100%;
    flex:4;
    padding: 10px 10px;
}
.promocode__input__btn{
   width: 100%;
   flex:1;
   background-color: royalblue;
   color:white;
   font-weight: bold;
   border: none;
   border-radius: 5px;
   margin-left: 10px;
   cursor: pointer;
}
.promo__checked__container{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}
.promo__container__bottom{
    display: flex;
    justify-content: center;
}
.promo__notice{
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: 100;
    font-size: 0.9rem;
    flex: 7;
}
.promo__cancelbtn {
    width: auto;
    border: none;
    border-radius: 5px;
    color:white;
    padding: 10px 18px;
    background-color:crimson;
    flex:1;
}
.promo__cancel{
    font-size: 0.9rem;
    padding: 0;
}
div.x__close {
    position: absolute;
    right: 25px;
    top: 0;
    color: #000;
    font-size: 35px;
    font-weight: bold;
  }

.x__close:hover,
.x__close:focus {
    color: red;
    cursor: pointer;
}
.close__icon{
    font-size: large;
}

/* input[type="email"],
input[type="text"]
{
  width: 100%;
  padding: 10px 15x;
  margin: 8px 0;
  display: inline-block;
  /* 아래넣으면, mui Text Field 안쪽 박스 생김 */
  /* border: 1px solid #ccc; */
  /* box-sizing: border-box; */
/* }  */
.error_span{
    display: flex;
    justify-content: center;
    background-color: rgba(242, 127, 127, 0.5);
    width: 100%;
    padding: 10px;
    color:whitesmoke;
}
.promo__btn {
    background-color: #04aa6d;
    color: white;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
}
.promo__btn:hover {
opacity: 0.8;
}
.avatar__selection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    margin: 10px auto;
    border:solid 1px #ccc;
    /* background-color: crimson; */
    /* cursor: pointer; */
}
.gatePic__selection{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5px;
    margin: 10px auto;
    border:solid 1px #ccc;
    /* background-color: crimson; */
    /* cursor: pointer; */
}
/* .avatar__selection:hover, .gatePic__selection:hover{
    opacity: 0.8;
}  */
