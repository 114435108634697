.posting__container{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.card__container{
    margin: 0 auto;
    display:grid;
    grid-template-columns: 1fr 1fr;
    min-width: none;
    gap: 0.7em;
}
/* 포스팅 추가버튼 */
.add__btn__box {
    cursor: pointer; 
}
.posting__btn{
    font-size: 40px;
}
.add__icon:hover{
    color:crimson
}
@media only screen and (max-width: 799px){
    .card__container{
        margin: 0 auto;
        display:grid;
        grid-template-columns: 1fr;
        gap: 0.7em;
    }
 }

/* 아래건 들 해결요함 */
.comment{
    margin-top: 20px;
}
.comment__title{
    font-size: 30px;
    margin-bottom: 20px;
}
.comment__container{
    margin-top: 40px;
}
.comment__form__title{
    font-size: 22px;
}
.comment__form__textarea{
    width:100%;
    height: 80px;
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid rgb(107,114,12);
}

.eric{
    text-align: end;
    font-size: 0.8rem;
    /* width: 65%; */
}
.eric span {
    display: block;
}
span.italic {
    font-style: italic;
  }
