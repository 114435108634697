/* @import url('https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap'); */

.san__card__container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__item{
    margin:0 auto;
}
/* CardContent 부문 */
.MM {
    margin-left: 5px;
}
.hh{
    margin-left: 10px;
    font-weight: bold;
}
.mm {
    font-weight: bold;
}
.dd{
    margin-left: 10px;
    font-weight: bold;
}

.t_diffy{
    background-color: royalblue;
    color:aliceblue;
    padding: 0 5px;
    margin-right: 5px;
    border-radius: 5px;
}
.t_dist{
    background-color: goldenrod;
    color:aliceblue;
    padding: 0 5px;
    margin-right: 5px;
    border-radius: 5px;
}
.t_timmy{
    background-color: green;
    color:aliceblue;
    padding: 0 5px;
    margin-right: 5px;
    border-radius: 5px;
}
.t_fee{
    background-color: steelblue;
    color:aliceblue;
    padding: 0 5px;
    margin-right: 0px;
    border-radius: 5px;
}
.t_fourth{
    font-size: 0.7em;
    padding-top: 5px;
}

.first,.second,.third{
    font-size: 0.8rem;
    font-weight: normal;
}
.third{
    margin-top:5px;
}
.comment__link{
    cursor: pointer;
}

.card__edit__icon{
    color:#147814;
    margin-right: 5px;
}
.card__delete__icon{
    color:crimson;
    margin-right: 5px;
}
.favor{
    position: relative;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card__icon__box{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.card__edit__icon:hover, .card__delete__icon:hover, .card__favor__icon:hover {
    cursor: pointer;
}
