.comment__container{
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    width: 90%;
    margin: 0 0px;
    padding: 5px;
    border-bottom: solid 1px rgb(147, 146, 146);
    border-radius: 10px;
}
.comment__avatar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
    margin: 5px 10px 5px 5px;
}
.comment__content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex:6;
}
/* .comment__content__upper{
    font-size: 0.9rem;
} */
.comment__content__upper__x{
    position: relative;
    display:flex;
    justify-content: space-between;
    font-size: 14px;
}
.comment__delete_x {
    cursor:pointer;
    color:crimson;
}
.comment__content__bottom{
    display: flex;
    padding: 1px;
    justify-content: space-between;
}
.comment__commenter{
    font-size: 0.8rem;
    padding-left: 10px;
    opacity:0.7;
}
.comment__bottom_right{
    display: flex;
    justify-content: space-between; 
}
.comment__date{
   display: flex;
   font-size: 0.7rem;
   opacity:0.7;
   margin-left: 10px;
}
.comment__reply{
    margin-right: 5px;
    cursor: pointer;
    opacity:0.9;
    color: darkblue;
    font-size: 12px;
    font-weight: bold;
}
.comment__edit{
    margin-right: 15px;
    cursor: pointer;
    opacity:0.9;
    color: darkgreen;
    font-size: 12px;
    font-weight: bold;
}
.replies{
    width:80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}