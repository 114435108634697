.attendee__yes{
    background-color: rgb(31, 132, 78);
    outline: none;
    border:none;
    cursor:pointer;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    color:white;
    
}
.attendee__no{
    background-color: royalblue;
    outline: none;
    border:none;
    cursor:pointer;
    border-radius: 5px;
    margin-top: 10px;
    padding: 5px 10px;
    color:white;
    cursor: pointer;
}
.attendee__list{
    margin-top: 5px;
}