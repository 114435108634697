.header__container{
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url("../../../public/images/header_bukhan.jpg") no-repeat center;
}

.mountain_title {
    height: 65px;
    padding:5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nanum Pen Script', cursive, sans-serif;
}
h2 {
    font-size: 45px;
}
@media only screen and (max-width: 799px){
    .mountain_title{
      display:none;
    }
    .nav__links{
      padding-top: 10px;
    }
    .logout__box, .login__box{
      margin-right: 5px;
    }
    .icon__box {
      margin-left: 5px;
    }
    
}