.single__card__container{
    position: relative;
    width: 100%;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: skyblue;
}
.single__card__box{
    position: relative;
    display: flex;
    flex-direction:column;
    width: 90%;
    max-width: 600px;
    padding: 0 auto;
}
.upper__detailed{
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;  
    align-items: center;
}
.bottom__detailed{
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: flex-start;  

}
.promo__container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.promo {
    display: flex;
    justify-content: flex-end;
    font-size: 0.8rem;
    color:white;
}
.promo span{
    cursor: pointer;
}

.sfirst{
    font-size: 1rem;
}
.course__name{
    font-size:1.2rem;
}
.single__card__form{
    width: 100%;
    max-width: 700px;
    position:fixed;
    bottom:0;
}
.single__courseDetail{
    margin-top: 1rem;
    font-size: 1rem;
}
.mainImage__container{
    width: 100%;

}
.attendees__container{
    width: 100%;
}
.comment__list {
    width: 100%;
    margin:0 auto;
}