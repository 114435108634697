div.bottom__nav{
    width: 100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
}
.nav__links{
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    list-style-type: none;
    margin: 0 auto;
    padding-bottom: 10px;
    overflow: hidden;
    

}
.signup__modal {
    font-size: 0.7rem;
    padding: 3px 15px;
    margin: 0px 5px 5px 0;
    border: solid 1.5px royalblue;
    border-radius: 5px;
    color:white;
    cursor: pointer;
}
.icon__box,.login__modal{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 10px;
    border: solid 1.5px royalblue;
    cursor: pointer;
}
.home__icon, .login__icon {
    color:royalblue;
    font-size: large;
    /* padding: 15px; */
}
.bottom__nav__sanname{
    font-family: 'Nanum Pen Script', cursive, sans-serif;
    font-size: 1.2rem;
    display: none;
}

.logout__icon {
    color:crimson;
    font-size: large;
}
.logout__box {
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 10px;
    border: solid 1.5px crimson;
    cursor: pointer;
}
.sign__div{
    display: flex;
    margin-right: 15px;

}
.signup__btn {
    display: none;
}
.display__name{
    color:black;
    font-size: 0.7rem;
    align-self: center;
}

@media only screen and (max-width: 799px){
    .bottom__nav__sanname{
        font-family: 'Courier New', Courier, monospace;
        font-weight: bold;
        font-size: 1.2rem;
        display: block;
    }

}
      /* font-family: 'Nanum Pen Script', cursive, sans-serif; */
