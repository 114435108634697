.notice__container{
  background-color: whitesmoke;
  border-radius: 20px;
  max-width: 490px;
  width: 90%;
}
.modal__notice{
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* margin: 2% auto 10% auto;  */
    /* margin: 0 auto;  */
    /* border: 1px solid red; */
    border-radius: 20px;
    padding: 30px 18px;
    width: 100%;
    height:80vh;
    max-width: 490px;
    overflow: hidden;
    z-index: 50;

    background-image: url('../../data/assets/hiking_2.png');
    background-repeat: no-repeat;
    background-blend-mode: lighten;
    background-size: auto;
    background-position: right bottom;
  }
  .tab__container {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 20px 0 0 10px;
    flex-direction: column;
    position: relative;
  }
/* .tab__btns{
  
} */
  .tab {
    padding: 5px 0 5px 0;
    text-align: center;
    width: 30%;
    background: rgba(128, 128, 128, 0.3);
    cursor: pointer;
    border:none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
    opacity: 0.7;
  }
  .tab.active__tab  {
    background-color: whitesmoke;
    color:royalblue;
    /* font-size: 1rem; */
    font-weight: bold;
    opacity: 1;
    border-bottom: 1px solid transparent;
  }
  .active__tab::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: royalblue;
  }
  .tab__contents{
    flex-grow : 1;
  }
  .content {
    /* background: white; */
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .active__content {
    display: block;
  }  

/* .notice__upper{
    
} */

ol>li {
    font-size: 0.75rem;
    line-height: 1.1rem;
    padding-bottom: 5px;
}

.tab:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}


/* button {
  padding: 5px 15px 5px 15px;
  border: none;
  opacity: 0.7;
  
} */
