.mainimage__container{
    height: 100%;
}
.container__slider {
    max-width: 700px;
    height: 500px;
    margin: 10px auto 0;
    position: relative;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  @media screen and (max-width: 700px){
    .container___slider {
      margin: 100px 10px 0;
    }
  }
  .slide {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    transition: opacity ease-in-out 0.4s;
  }
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .active__anim {
    opacity: 1;
  }
  
  .btn__slide {
    width: 40px;
    height:40px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid whitesmoke;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .container-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
  }
  .dot.active {
    background: rgb(32, 32, 32);
  }
  .image__loader {
    display: flex;
    justify-content: flex-end;
    font-size: .6rem;
    opacity: 0.6;
  }
  .delete_x {
    position:absolute;
    top:-5px;
    right:5px;
    font-size: 2rem;
    font-weight: bold;
    color: crimson;
    cursor:pointer;
    z-index: 100;
}