.comment__list__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 80px;
    margin-top:20px;
    /* background-color: blue; */
}
.comment__list__box{
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-self:flex-end;
    margin: 0 auto;
}